import validate from "/var/www/html/node_modules/nuxt/dist/pages/runtime/validate.js";
export const globalMiddleware = [
  validate
]
export const namedMiddleware = {
  activated: () => import("/var/www/html/middleware/activated.js"),
  auth: () => import("/var/www/html/middleware/auth.js"),
  "mobile-verified": () => import("/var/www/html/middleware/mobile-verified.js"),
  stripe: () => import("/var/www/html/middleware/stripe.js"),
  subscribed: () => import("/var/www/html/middleware/subscribed.js"),
  "valid-clearance": () => import("/var/www/html/middleware/valid-clearance.js")
}