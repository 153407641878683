import revive_payload_client_4sVQNw7RlN from "/var/www/html/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import router_jmwsqit4Rs from "/var/www/html/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import plugin_vue3_A0OWXRrUgq from "/var/www/html/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.mjs";
import components_plugin_KR1HBZs4kY from "/var/www/html/.nuxt/components.plugin.mjs";
import unhead_KgADcZ0jPj from "/var/www/html/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import prefetch_client_5tzzN0oIVL from "/var/www/html/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_client_LcKgStRyi6 from "/var/www/html/node_modules/nuxt-gtag/dist/runtime/plugin.client.mjs";
import plugin_8SbxDRbG6Y from "/var/www/html/node_modules/dayjs-nuxt/dist/runtime/plugin.mjs";
import chunk_reload_client_UciE0i6zes from "/var/www/html/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import _01_api_client_1SJoTor3pd from "/var/www/html/plugins/01.api.client.js";
import _02_init_client_DInL995J4O from "/var/www/html/plugins/02.init.client.js";
import _03_vue_toastify_client_1azqKhJVVo from "/var/www/html/plugins/03.vue-toastify.client.js";
import _04_stripe_client_vMmdMjArcv from "/var/www/html/plugins/04.stripe.client.js";
import _05_mitt_client_cDZ4OljR6S from "/var/www/html/plugins/05.mitt.client.js";
import cookieconsent_client_k0DKrALfet from "/var/www/html/plugins/cookieconsent.client.ts";
import headless_ui_sXgzlVd9r2 from "/var/www/html/plugins/headless-ui.js";
import i18n_sVHQBgnb3t from "/var/www/html/plugins/i18n.js";
import popper_KwS8fMgSzV from "/var/www/html/plugins/popper.js";
import primevue_TdXjRgL1MA from "/var/www/html/plugins/primevue.js";
export default [
  revive_payload_client_4sVQNw7RlN,
  router_jmwsqit4Rs,
  plugin_vue3_A0OWXRrUgq,
  components_plugin_KR1HBZs4kY,
  unhead_KgADcZ0jPj,
  prefetch_client_5tzzN0oIVL,
  plugin_client_LcKgStRyi6,
  plugin_8SbxDRbG6Y,
  chunk_reload_client_UciE0i6zes,
  _01_api_client_1SJoTor3pd,
  _02_init_client_DInL995J4O,
  _03_vue_toastify_client_1azqKhJVVo,
  _04_stripe_client_vMmdMjArcv,
  _05_mitt_client_cDZ4OljR6S,
  cookieconsent_client_k0DKrALfet,
  headless_ui_sXgzlVd9r2,
  i18n_sVHQBgnb3t,
  popper_KwS8fMgSzV,
  primevue_TdXjRgL1MA
]